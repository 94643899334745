import PropTypes from 'prop-types';

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Switch from '@mui/material/Switch';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import ChipsList from '../../components/ChipsList/ChipsList';
import Error from '../../components/Error/Error';
import ImageInput from '../../components/ImageInput/ImageInput';
import { Context as UserContext } from '../../context/UserContext';
import { Context as ProductPageContext } from '../../context/ProductPageContext';
import { Context as BenefitGroupsContext } from '../../context/BenefitGroupsContext';
import { Context as StickyProductsContext } from '../../context/StickyProductsContext';

import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import Wysiwyg from '../../components/Wysiwyg/Wysiwyg';
import { getProductDefaultImage } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '26px',
  },
  subTitle: {
    marginBottom: theme.spacing(2),
    marginTop: '-10px',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 400,
  },
  container: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },

  productsIcon: {
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
  },
  benefit: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(3),
    borderBottom: '2px dashed #cbcbcb',
    paddingBottom: theme.spacing(2),
  },
  benefitLeft: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  listItemIcons: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(3),
    borderLeft: '2px dashed #cbcbcb',
  },
  listItemIcon: {
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  bulletPoint: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    border: '1px solid #cbcbcb',
  },
  products: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  product: {
    width: `calc(33.33% - ${theme.spacing(2)}px)`,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    border: '1px solid #c4c4c4',
    borderRadius: '5px',
  },
  productImage: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
}));

export default function ProductPagePage({ onRender }) {
  // VARS
  const classes = useStyles();
  const { id } = useParams();

  // STATE
  const [slug, setSlug] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [headline, setHeadline] = useState('');
  const [ctaAlert, setCtaAlert] = useState('');
  const [headlineBenefits, setHeadlineBenefits] = useState([]);
  const [topBenefits, setTopBenefits] = useState([]);
  const [supplementFacts, setSupplementFacts] = useState('');
  const [howToTakeImage, setHowToTakeImage] = useState('');
  const [benefits, setBenefits] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [howToTake, setHowToTake] = useState([]);
  const [lovers, setLovers] = useState([]);
  const [faq, setFAQ] = useState([]);
  const [comparisonTableItems, setComparisonTableItems] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState(null);

  // CONTEXTS
  const {
    state: { user },
  } = useContext(UserContext);

  const {
    state: { productPage, loading: productPageLoading, error: productPageError },
    loadProductPage,
    updateProductPage,
  } = useContext(ProductPageContext);

  const {
    state: { benefitGroups, loading: benefitGroupsLoading, error: benefitGroupsError },
    loadBenefitGroups,
  } = useContext(BenefitGroupsContext);

  const {
    state: { stickyProducts, stickyProductsLoading, stickyProductsError },
    loadStickyProducts,
  } = useContext(StickyProductsContext);

  // EFFECTS
  useEffect(() => {
    if (user) {
      loadProductPage(id);
      loadStickyProducts();
      loadBenefitGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (productPage) {
      onRender(`Product Page - ${productPage.slug}`);
      // Set values after productPage is loaded
      setSlug(productPage.slug);
      setSubtitle(productPage.subtitle);
      setHeadline(productPage.headline);
      setCtaAlert(productPage.ctaAlert);
      setBenefits(productPage.benefits);
      setReviews(productPage.reviews);
      setHowToTake(productPage.howToTake);
      setLovers(productPage.lovers);
      setHeadlineBenefits(productPage.headlineBenefits);
      setTopBenefits(productPage.topBenefits);
      setFAQ(productPage.faq);
      setComparisonTableItems(productPage.comparisonTableItems);
      setRelatedProducts(productPage.relatedProducts);
      setSupplementFacts(productPage.supplementFacts);
      setHowToTakeImage(productPage.howToTakeImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPage]);

  if (productPageError || benefitGroupsError || stickyProductsError) {
    return <Error error={productPageError || benefitGroupsError || stickyProductsError} />;
  }

  if (!productPage) {
    return null;
  }

  function handleProductPageUpdate(update) {
    updateProductPage(id, update);
  }

  // Render Benefits
  const Benefits = () => {
    const [state, setState] = useState(productPage.benefits);

    function handleBlur() {
      handleProductPageUpdate({
        benefits: state,
      });
    }

    function handleUpdateItem(e, index) {
      const newItems = [...state];
      newItems[index][e.target.name] = e.target.value;
      setState(newItems);
    }

    function handleAddItem() {
      handleProductPageUpdate({
        benefits: [
          ...state,
          {
            image: '',
            title: '',
            text: '',
          },
        ],
      });
    }

    function handleDeleteItem(title) {
      handleProductPageUpdate({
        benefits: state.filter((item) => item.title !== title),
      });
    }

    function renderItemsList() {
      if (!state.length) {
        return (
          <IconButton
            size='small'
            color='primary'
            aria-label='Add'
            component='span'
            onClick={() => handleAddItem()}
            className={classes.listItemIcon}
          >
            <AddIcon />
          </IconButton>
        );
      }

      return state.map(({ image, title, text }, index) => (
        <Box className={classes.benefit} key={index}>
          <Box className={classes.benefitLeft}>
            <TextField
              name='title'
              label='Title'
              value={title}
              className={classes.textField}
              onChange={(e) => handleUpdateItem(e, index)}
              onBlur={handleBlur}
            />
            <TextField
              name='text'
              label='Text'
              value={text}
              multiline
              maxRows={4}
              className={classes.textField}
              onChange={(e) => handleUpdateItem(e, index)}
              onBlur={handleBlur}
            />
            <ImageInput
              label='Image'
              name='image'
              helperText='120x120'
              value={image}
              onChange={(e) => handleUpdateItem(e, index)}
              onBlur={handleBlur}
            />
          </Box>
          <Box className={classes.listItemIcons}>
            <IconButton
              color='secondary'
              aria-label='delete benefit'
              component='span'
              size='small'
              className={classes.listItemIcon}
              onClick={() => handleDeleteItem(title)}
            >
              <DeleteIcon />
            </IconButton>

            <IconButton
              size='small'
              color='primary'
              aria-label='Add'
              component='span'
              onClick={() => handleAddItem()}
              className={classes.listItemIcon}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      ));
    }

    return (
      <Paper className={classes.paper}>
        <Typography className={classes.title}>Benefits</Typography>
        {renderItemsList()}
      </Paper>
    );
  };

  const Problems = () => {
    const [problems, setProblems] = useState(productPage.problems);

    function handleBlur() {
      handleProductPageUpdate({
        problems,
      });
    }

    function handleUpdateProblem(e, index) {
      const newProblems = [...problems.list];
      newProblems[index][e.target.name] = e.target.value;
      setProblems({
        ...problems,
        list: newProblems,
      });
    }

    function handleAddProblem() {
      handleProductPageUpdate({
        problems: {
          ...problems,
          list: [
            ...problems.list,
            {
              image: '',
              title: '',
              text: '',
            },
          ],
        },
      });
    }

    function handleDeleteProblem(title) {
      handleProductPageUpdate({
        problems: {
          ...problems,
          list: problems.list.filter((item) => item.title !== title),
        },
      });
    }

    function renderProblemsList() {
      if (!problems.list.length) {
        return (
          <IconButton
            size='small'
            color='primary'
            aria-label='Add'
            component='span'
            onClick={() => handleAddProblem()}
            className={classes.listItemIcon}
          >
            <AddIcon />
          </IconButton>
        );
      }

      return problems.list.map(({ image, title, text }, index) => (
        <Box className={classes.benefit} key={index}>
          <Box className={classes.benefitLeft}>
            <ImageInput
              label='Image'
              name='image'
              helperText='350x296'
              value={image}
              onChange={(e) => handleUpdateProblem(e, index)}
              onBlur={handleBlur}
            />
            <TextField
              name='title'
              label='Title'
              value={title}
              className={classes.textField}
              onChange={(e) => handleUpdateProblem(e, index)}
              onBlur={handleBlur}
            />
            <TextField
              name='text'
              label='Text'
              value={text}
              multiline
              maxRows={4}
              className={classes.textField}
              onChange={(e) => handleUpdateProblem(e, index)}
              onBlur={handleBlur}
            />
          </Box>
          <Box className={classes.listItemIcons}>
            <IconButton
              color='secondary'
              aria-label='delete benefit'
              component='span'
              size='small'
              className={classes.listItemIcon}
              onClick={() => handleDeleteProblem(title)}
            >
              <DeleteIcon />
            </IconButton>

            <IconButton
              size='small'
              color='primary'
              aria-label='Add'
              component='span'
              onClick={() => handleAddProblem()}
              className={classes.listItemIcon}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      ));
    }

    return (
      <Paper className={classes.paper}>
        <Typography className={classes.title}>Problems</Typography>

        <TextField
          name='title'
          label='Title'
          fullWidth
          value={problems.title}
          className={classes.textField}
          onChange={(e) => setProblems({ ...problems, title: e.target.value })}
          onBlur={handleBlur}
        />
        <TextField
          name='subtitle'
          label='Subtitle'
          fullWidth
          multiline
          maxRows={4}
          helperText='Supports HTML'
          value={problems.subtitle}
          className={classes.textField}
          onChange={(e) => setProblems({ ...problems, subtitle: e.target.value })}
          onBlur={handleBlur}
        />
        <Typography variant='h6' align='center' gutterBottom>
          List
        </Typography>
        {renderProblemsList()}
      </Paper>
    );
  };

  const BigBenefits = () => {
    const [state, setState] = useState(productPage.bigBenefits);

    function handleBlur() {
      handleProductPageUpdate({
        bigBenefits: state,
      });
    }

    function handleUpdateItem(e, index) {
      const newItems = [...state.list];
      newItems[index][e.target.name] = e.target.value;
      setState({
        ...state,
        list: newItems,
      });
    }

    function handleAddItem() {
      handleProductPageUpdate({
        bigBenefits: {
          ...state,
          list: [
            ...state.list,
            {
              image: '',
              title: '',
            },
          ],
        },
      });
    }

    function handleDeleteProblem(title) {
      handleProductPageUpdate({
        problems: {
          ...state,
          list: state.list.filter((item) => item.title !== title),
        },
      });
    }

    function renderItemsList() {
      if (!state.list.length) {
        return (
          <IconButton
            size='small'
            color='primary'
            aria-label='Add'
            component='span'
            onClick={() => handleAddItem()}
            className={classes.listItemIcon}
          >
            <AddIcon />
          </IconButton>
        );
      }

      return state.list.map(({ image, text }, index) => (
        <Box className={classes.benefit} key={index}>
          <Box className={classes.benefitLeft}>
            <ImageInput
              label='Image'
              name='image'
              helperText='350x296'
              value={image}
              onChange={(e) => handleUpdateItem(e, index)}
              onBlur={handleBlur}
            />

            <TextField
              name='text'
              label='Text'
              value={text}
              multiline
              maxRows={4}
              className={classes.textField}
              onChange={(e) => handleUpdateItem(e, index)}
              onBlur={handleBlur}
            />
          </Box>
          <Box className={classes.listItemIcons}>
            <IconButton
              color='secondary'
              aria-label='delete benefit'
              component='span'
              size='small'
              className={classes.listItemIcon}
              onClick={() => handleDeleteProblem(text)}
            >
              <DeleteIcon />
            </IconButton>

            <IconButton
              size='small'
              color='primary'
              aria-label='Add'
              component='span'
              onClick={() => handleAddItem()}
              className={classes.listItemIcon}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      ));
    }

    return (
      <Paper className={classes.paper}>
        <Typography className={classes.title}>Big Benefits</Typography>

        <TextField
          name='title'
          label='Title'
          fullWidth
          value={state.title}
          className={classes.textField}
          onChange={(e) => setState({ ...state, title: e.target.value })}
          onBlur={handleBlur}
        />
        <TextField
          name='subtitle'
          label='Subtitle'
          fullWidth
          multiline
          maxRows={4}
          helperText='Supports HTML'
          value={state.subtitle}
          className={classes.textField}
          onChange={(e) => setState({ ...state, subtitle: e.target.value })}
          onBlur={handleBlur}
        />
        <ImageInput
          name='desktopImage'
          label='Desktop Image'
          fullWidth
          multiline
          maxRows={4}
          helperText='481x744'
          value={state.desktopImage}
          className={classes.textField}
          onChange={(e) => setState({ ...state, desktopImage: e.target.value })}
          onBlur={handleBlur}
        />
        <ImageInput
          name='mobileImage'
          label='Mobile Image'
          fullWidth
          multiline
          maxRows={4}
          helperText='156x444'
          value={state.mobileImage}
          className={classes.textField}
          onChange={(e) => setState({ ...state, mobileImage: e.target.value })}
          onBlur={handleBlur}
        />
        <Typography variant='h6' align='center' gutterBottom>
          List
        </Typography>
        {renderItemsList()}
      </Paper>
    );
  };

  const Results = () => {
    const [state, setState] = useState(productPage.results);

    function handleBlur() {
      handleProductPageUpdate({
        results: state,
      });
    }

    function handleUpdateItem(e, index) {
      const newItems = [...state.list];
      newItems[index][e.target.name] = e.target.value;
      setState({
        ...state,
        list: newItems,
      });
    }

    function handleAddItem() {
      handleProductPageUpdate({
        results: {
          ...state,
          list: [
            ...state.list,
            {
              title: '',
              text: '',
            },
          ],
        },
      });
    }

    function handleDeleteProblem(title) {
      handleProductPageUpdate({
        problems: {
          ...state,
          list: state.list.filter((item) => item.title !== title),
        },
      });
    }

    function renderItemsList() {
      if (!state.list.length) {
        return (
          <IconButton
            size='small'
            color='primary'
            aria-label='Add'
            component='span'
            onClick={() => handleAddItem()}
            className={classes.listItemIcon}
          >
            <AddIcon />
          </IconButton>
        );
      }

      return state.list.map(({ title, text }, index) => (
        <Box className={classes.benefit} key={index}>
          <Box className={classes.benefitLeft}>
            <TextField
              name='title'
              label='Title'
              value={title}
              className={classes.textField}
              onChange={(e) => handleUpdateItem(e, index)}
              onBlur={handleBlur}
            />
            <TextField
              name='text'
              label='Text'
              multiline
              maxRows={4}
              value={text}
              className={classes.textField}
              onChange={(e) => handleUpdateItem(e, index)}
              onBlur={handleBlur}
            />
          </Box>
          <Box className={classes.listItemIcons}>
            <IconButton
              color='secondary'
              aria-label='delete item'
              component='span'
              size='small'
              className={classes.listItemIcon}
              onClick={() => handleDeleteProblem(text)}
            >
              <DeleteIcon />
            </IconButton>

            <IconButton
              size='small'
              color='primary'
              aria-label='Add'
              component='span'
              onClick={() => handleAddItem()}
              className={classes.listItemIcon}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      ));
    }

    return (
      <Paper className={classes.paper}>
        <Typography className={classes.title}>Results</Typography>

        <TextField
          name='title'
          label='Title'
          fullWidth
          value={state.title}
          className={classes.textField}
          onChange={(e) => setState({ ...state, title: e.target.value })}
          onBlur={handleBlur}
        />
        <ImageInput
          name='image'
          label='Image'
          fullWidth
          value={state.image}
          className={classes.textField}
          onChange={(e) => setState({ ...state, image: e.target.value })}
          onBlur={handleBlur}
        />
        <ImageInput
          name='backgroundDesktop'
          label='Desktop Background Image'
          fullWidth
          helperText='1446x886'
          value={state.backgroundDesktop}
          className={classes.textField}
          onChange={(e) => setState({ ...state, backgroundDesktop: e.target.value })}
          onBlur={handleBlur}
        />
        <ImageInput
          name='backgroundMobile'
          label='Mobile Background Image'
          fullWidth
          helperText='380x384'
          value={state.backgroundMobile}
          className={classes.textField}
          onChange={(e) => setState({ ...state, backgroundMobile: e.target.value })}
          onBlur={handleBlur}
        />
        <Typography variant='h6' align='center' gutterBottom>
          List
        </Typography>
        {renderItemsList()}
      </Paper>
    );
  };

  // Lovers
  function renderLovers() {
    return (
      <>
        <ChipsList
          items={lovers}
          onUpdate={(newItems) =>
            handleProductPageUpdate({
              lovers: newItems,
            })
          }
        />
        {lovers.map((item, index) => (
          <img key={index} src={item} alt='lover' height={100} />
        ))}
      </>
    );
  }

  function renderVideoReviews() {
    return (
      <>
        <ChipsList
          items={productPage.videoReviews}
          onUpdate={(newItems) =>
            handleProductPageUpdate({
              videoReviews: newItems,
            })
          }
        />
      </>
    );
  }

  // Reviews
  function renderReviews() {
    function handleUpdateReview(e, index) {
      const newReviews = [...reviews];
      newReviews[index][e.target.name] = e.target.value;
      setReviews(newReviews);
    }

    function handleAddReview() {
      handleProductPageUpdate({
        reviews: [
          ...reviews,
          {
            image: '',
            title: '',
            text: '',
            name: '',
          },
        ],
      });
    }

    function handleDeleteReview(name) {
      handleProductPageUpdate({
        reviews: reviews.filter((item) => item.name !== name),
      });
    }

    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <FormGroup className={classes.textField}>
            <FormControlLabel
              control={
                <Switch
                  value={productPage.isReviewsActive}
                  checked={productPage.isReviewsActive}
                  onChange={(e) =>
                    handleProductPageUpdate({
                      isReviewsActive: e.target.checked,
                    })
                  }
                />
              }
              label='Active'
            />
          </FormGroup>
        </div>
        {!reviews.length ? (
          <IconButton
            size='small'
            color='primary'
            aria-label='Add'
            component='span'
            onClick={() => handleAddReview()}
            className={classes.listItemIcon}
          >
            <AddIcon />
          </IconButton>
        ) : (
          reviews.map(({ image, title, text, name }, index) => (
            <Box className={classes.benefit} key={index}>
              <Box className={classes.benefitLeft}>
                <ImageInput
                  label='Image'
                  name='image'
                  helperText='320x320'
                  value={image}
                  onChange={(e) => handleUpdateReview(e, index)}
                  onBlur={() =>
                    handleProductPageUpdate({
                      reviews,
                    })
                  }
                />
                <TextField
                  name='title'
                  label='Title'
                  value={title}
                  className={classes.textField}
                  onChange={(e) => handleUpdateReview(e, index)}
                  onBlur={() =>
                    handleProductPageUpdate({
                      reviews,
                    })
                  }
                />
                <TextField
                  name='text'
                  label='Text'
                  value={text}
                  multiline
                  maxRows={4}
                  className={classes.textField}
                  onChange={(e) => handleUpdateReview(e, index)}
                  onBlur={() =>
                    handleProductPageUpdate({
                      reviews,
                    })
                  }
                />
                <TextField
                  name='name'
                  label='Name'
                  value={name}
                  onChange={(e) => handleUpdateReview(e, index)}
                  onBlur={() =>
                    handleProductPageUpdate({
                      reviews,
                    })
                  }
                />
              </Box>
              <Box className={classes.listItemIcons}>
                <IconButton
                  color='secondary'
                  aria-label='delete review'
                  component='span'
                  size='small'
                  className={classes.listItemIcon}
                  onClick={() => handleDeleteReview(name)}
                >
                  <DeleteIcon />
                </IconButton>

                <IconButton
                  size='small'
                  color='primary'
                  aria-label='Add'
                  component='span'
                  onClick={() => handleAddReview()}
                  className={classes.listItemIcon}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          ))
        )}
      </>
    );
  }

  // How to take
  function renderHowToTake() {
    function handleUpdateHowToTake(e, index) {
      const newHowToTake = [...howToTake];
      newHowToTake[index][e.target.name] = e.target.value;
      setHowToTake(newHowToTake);
    }

    function handleAddHowToTake() {
      handleProductPageUpdate({
        howToTake: [
          ...howToTake,
          {
            image: '',
            title: '',
            text: '',
          },
        ],
      });
    }

    function handleDeleteHowToTake(title) {
      handleProductPageUpdate({
        howToTake: howToTake.filter((item) => item.title !== title),
      });
    }

    return (
      <>
        {!howToTake.length ? (
          <IconButton
            size='small'
            color='primary'
            aria-label='Add'
            component='span'
            onClick={() => handleAddHowToTake()}
            className={classes.listItemIcon}
          >
            <AddIcon />
          </IconButton>
        ) : (
          howToTake.map(({ image, title, text }, index) => (
            <Box className={classes.benefit} key={index}>
              <Box className={classes.benefitLeft}>
                <ImageInput
                  label='Icon'
                  name='image'
                  helperText='width: 35px, height: auto'
                  value={image}
                  onChange={(e) => handleUpdateHowToTake(e, index)}
                  onBlur={() =>
                    handleProductPageUpdate({
                      howToTake,
                    })
                  }
                />
                <TextField
                  name='title'
                  label='Title'
                  value={title}
                  className={classes.textField}
                  onChange={(e) => handleUpdateHowToTake(e, index)}
                  onBlur={() =>
                    handleProductPageUpdate({
                      howToTake,
                    })
                  }
                />
                <TextField
                  name='text'
                  label='Text'
                  value={text}
                  multiline
                  maxRows={4}
                  className={classes.textField}
                  onChange={(e) => handleUpdateHowToTake(e, index)}
                  onBlur={() =>
                    handleProductPageUpdate({
                      howToTake,
                    })
                  }
                />
              </Box>
              <Box className={classes.listItemIcons}>
                <IconButton
                  color='secondary'
                  aria-label='delete step'
                  component='span'
                  size='small'
                  className={classes.listItemIcon}
                  onClick={() => handleDeleteHowToTake(title)}
                >
                  <DeleteIcon />
                </IconButton>

                <IconButton
                  size='small'
                  color='primary'
                  aria-label='Add'
                  component='span'
                  onClick={() => handleAddHowToTake()}
                  className={classes.listItemIcon}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          ))
        )}
      </>
    );
  }

  // Labels
  function renderLabels() {
    function handleLabelsChange(values) {
      const newValues = values.map((item) => item.id);

      handleProductPageUpdate({
        labels: newValues,
      });
    }

    const options = ['vegan', 'gluten', 'sugar', 'gmo', 'fillers', 'hexane'].map((item) => ({
      id: item,
      name: item,
    }));

    return (
      <>
        <Box className={classes.textField}>
          <MultiSelect
            label='Labels'
            optionLabel='name'
            options={options}
            value={productPage.labels.map((item) => ({
              id: item,
              name: item,
            }))}
            onChange={handleLabelsChange}
          />
        </Box>
      </>
    );
  }

  // Benefit groups
  function renderBenefitGroups() {
    function handleBenefitGroupsChange(values) {
      handleProductPageUpdate({
        benefitGroups: values,
      });
    }

    return (
      <Box className={classes.textField}>
        <MultiSelect
          label='Benefit groups (categories)'
          optionLabel='name'
          options={benefitGroups}
          value={productPage.benefitGroups}
          onChange={handleBenefitGroupsChange}
          getOptionSelected={(option, value) => option._id === value._id}
        />
      </Box>
    );
  }

  // FAQ
  function renderFAQ() {
    function handleUpdateFAQ(e, index) {
      const newFAQ = [...faq];
      newFAQ[index][e.target.name] = e.target.value;
      setFAQ(newFAQ);
    }

    function handleAddFAQ() {
      handleProductPageUpdate({
        faq: [
          ...faq,
          {
            title: '',
            content: '',
          },
        ],
      });
    }

    function handleDeleteFAQ(title) {
      handleProductPageUpdate({
        faq: faq.filter((item) => item.title !== title),
      });
    }

    function handleDragEnd(result) {
      if (!result.destination) return;

      const reorderedFAQ = Array.from(faq);
      const [movedItem] = reorderedFAQ.splice(result.source.index, 1);
      reorderedFAQ.splice(result.destination.index, 0, movedItem);

      setFAQ(reorderedFAQ);
      handleProductPageUpdate({ faq: reorderedFAQ });
    }

    if (!faq.length) {
      return (
        <IconButton
          size='small'
          color='primary'
          aria-label='Add'
          component='span'
          onClick={() => handleAddFAQ()}
          className={classes.listItemIcon}
        >
          <AddIcon />
        </IconButton>
      );
    }

    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='faq-list'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {faq.map(({ title, content }, index) => (
                <Draggable key={title} draggableId={title} index={index}>
                  {(provided) => (
                    <Box
                      className={classes.benefit}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Box className={classes.benefitLeft}>
                        <TextField
                          name='title'
                          label='Question'
                          value={title}
                          onChange={(e) => handleUpdateFAQ(e, index)}
                          className={classes.textField}
                          onBlur={() =>
                            handleProductPageUpdate({
                              faq,
                            })
                          }
                        />
                        <TextField
                          name='content'
                          label='Answer'
                          value={content}
                          multiline
                          maxRows={4}
                          className={classes.textField}
                          onChange={(e) => handleUpdateFAQ(e, index)}
                          onBlur={() =>
                            handleProductPageUpdate({
                              faq,
                            })
                          }
                        />
                      </Box>
                      <Box className={classes.listItemIcons}>
                        <IconButton
                          color='secondary'
                          aria-label='delete faq'
                          component='span'
                          size='small'
                          className={classes.listItemIcon}
                          onClick={() => handleDeleteFAQ(title)}
                        >
                          <DeleteIcon />
                        </IconButton>

                        <IconButton
                          size='small'
                          color='primary'
                          aria-label='Add'
                          component='span'
                          onClick={() => handleAddFAQ()}
                          className={classes.listItemIcon}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  // Related products
  function renderRelatedProducts() {
    function handleUpdateRelatedProducts(e, index) {
      const newRelatedProducts = {
        ...relatedProducts,
      };

      newRelatedProducts.items[index][e.target.name] = e.target.value;
      setRelatedProducts(newRelatedProducts);
    }

    function handleUpdateRelatedProductsRelation(e) {
      setRelatedProducts({
        ...relatedProducts,
        relation: e.target.value,
      });
    }

    function handleAddRelatedProducts() {
      if (!relatedProducts) {
        handleProductPageUpdate({
          relatedProducts: {
            relation: '',
            items: [
              {
                label: '',
                link: '',
              },
            ],
          },
        });
      } else {
        handleProductPageUpdate({
          relatedProducts: {
            ...relatedProducts,
            items: [
              ...relatedProducts.items,
              {
                label: '',
                link: '',
              },
            ],
          },
        });
      }
    }

    function handleDeleteRelatedProducts(link) {
      if (relatedProducts.items.length === 1) {
        handleProductPageUpdate({
          relatedProducts: null,
        });
      } else {
        handleProductPageUpdate({
          relatedProducts: {
            ...relatedProducts,
            items: relatedProducts.items.filter((item) => item.link !== link),
          },
        });
      }
    }

    if (!relatedProducts) {
      return (
        <div style={{ textAlign: 'center' }}>
          <IconButton
            size='small'
            color='primary'
            aria-label='Add'
            component='span'
            onClick={() => handleAddRelatedProducts()}
            className={classes.listItemIcon}
          >
            <AddIcon />
          </IconButton>
        </div>
      );
    }

    return (
      <>
        <div>
          <h4 className={classes.subTitle} variant='subtitle4'>
            (Including this product)
          </h4>
        </div>
        <TextField
          name='relation'
          label='Relation name'
          helperText='Source, flavor, etc...'
          value={relatedProducts.relation}
          onChange={handleUpdateRelatedProductsRelation}
          className={classes.textField}
          onBlur={() =>
            handleProductPageUpdate({
              relatedProducts,
            })
          }
        />
        {relatedProducts.items.map(({ label, link }, index) => (
          <Box className={classes.benefit} key={index}>
            <Box className={classes.benefitLeft}>
              <TextField
                name='label'
                label='Relation label'
                helperText='Bovine, Citrus, etc...'
                value={label}
                onChange={(e) => handleUpdateRelatedProducts(e, index)}
                className={classes.textField}
                onBlur={() =>
                  handleProductPageUpdate({
                    relatedProducts,
                  })
                }
              />
              <TextField
                name='link'
                label='Product link'
                value={link}
                onChange={(e) => handleUpdateRelatedProducts(e, index)}
                className={classes.textField}
                onBlur={() =>
                  handleProductPageUpdate({
                    relatedProducts,
                  })
                }
              />
            </Box>
            <Box className={classes.listItemIcons}>
              <IconButton
                color='secondary'
                aria-label='delete related product'
                component='span'
                size='small'
                className={classes.listItemIcon}
                onClick={() => handleDeleteRelatedProducts(link)}
              >
                <DeleteIcon />
              </IconButton>

              <IconButton
                size='small'
                color='primary'
                aria-label='Add'
                component='span'
                onClick={() => handleAddRelatedProducts()}
                className={classes.listItemIcon}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
      </>
    );
  }

  // Comparison table image
  const ComparisonTableImage = () => {
    const [image, setImage] = useState(productPage.comparisonTableImage);

    return (
      <ImageInput
        name='image'
        label='Image URL'
        value={image}
        fullWidth
        className={classes.textField}
        onChange={(e) => setImage(e.target.value)}
        onBlur={() =>
          handleProductPageUpdate({
            comparisonTableImage: image,
          })
        }
      />
    );
  };

  // Comparison table alt image
  const ComparisonTableAltImage = () => {
    const [image, setImage] = useState(productPage.comparisonTableIAltImage);

    return (
      <ImageInput
        name='image'
        label='Alternative Image URL (pills, powder etc...)'
        value={image}
        fullWidth
        className={classes.textField}
        onChange={(e) => setImage(e.target.value)}
        onBlur={() =>
          handleProductPageUpdate({
            comparisonTableIAltImage: image,
          })
        }
      />
    );
  };
  const ComparisonTableTitle = () => {
    const [value, setValue] = useState(productPage.comparisonTableTitle);

    return (
      <TextField
        name='title'
        label='Section Title'
        value={value}
        fullWidth
        className={classes.textField}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          handleProductPageUpdate({
            comparisonTableTitle: value,
          })
        }
      />
    );
  };

  const ComparisonTableSubtitle = () => {
    const [value, setValue] = useState(productPage.comparisonTableSubtitle);

    return (
      <TextField
        name='subtitle'
        label='Section subtitle'
        value={value}
        fullWidth
        multiline
        maxRows={4}
        className={classes.textField}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          handleProductPageUpdate({
            comparisonTableSubtitle: value,
          })
        }
      />
    );
  };

  // Comparison table items
  function renderComparisonTable() {
    function handleUpdateComparisonTableItem(e, index) {
      const newComparisonTableItems = [...comparisonTableItems];
      newComparisonTableItems[index][e.target.name] = e.target.value;
      setComparisonTableItems(newComparisonTableItems);
    }

    function handleAddComparisonTableItem() {
      handleProductPageUpdate({
        comparisonTableItems: [
          ...comparisonTableItems,
          {
            title: '',
            text: '',
          },
        ],
      });
    }

    function handleDeleteComparisonTableItem(title) {
      handleProductPageUpdate({
        comparisonTableItems: comparisonTableItems.filter((item) => item.title !== title),
      });
    }

    if (!comparisonTableItems.length) {
      return (
        <IconButton
          size='small'
          color='primary'
          aria-label='Add'
          component='span'
          onClick={() => handleAddComparisonTableItem()}
          className={classes.listItemIcon}
        >
          <AddIcon />
        </IconButton>
      );
    }

    return (
      <>
        <ComparisonTableTitle />
        <ComparisonTableSubtitle />
        <ComparisonTableImage />
        <ComparisonTableAltImage />
        <Typography variant='h6' align='center' gutterBottom>
          Table content
        </Typography>
        {comparisonTableItems.map(({ title, text }, index) => (
          <Box className={classes.benefit} key={index}>
            <Box className={classes.benefitLeft}>
              <TextField
                name='title'
                label='Title'
                value={title}
                helperText='Our'
                className={classes.textField}
                onChange={(e) => handleUpdateComparisonTableItem(e, index)}
                onBlur={() =>
                  handleProductPageUpdate({
                    comparisonTableItems,
                  })
                }
              />
              <TextField
                name='text'
                label='Text'
                value={text}
                multiline
                maxRows={4}
                helperText='Their'
                className={classes.textField}
                onChange={(e) => handleUpdateComparisonTableItem(e, index)}
                onBlur={() =>
                  handleProductPageUpdate({
                    comparisonTableItems,
                  })
                }
              />
            </Box>
            <Box className={classes.listItemIcons}>
              <IconButton
                color='secondary'
                aria-label='delete item'
                component='span'
                size='small'
                className={classes.listItemIcon}
                onClick={() => handleDeleteComparisonTableItem(title)}
              >
                <DeleteIcon />
              </IconButton>

              <IconButton
                size='small'
                color='primary'
                aria-label='Add'
                component='span'
                onClick={() => handleAddComparisonTableItem()}
                className={classes.listItemIcon}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
      </>
    );
  }

  function handleWysiwygBlur(value) {
    handleProductPageUpdate({
      customSectionContent: value,
    });
  }

  // statistics
  const Statistics = () => {
    const [state, setState] = useState(productPage.statistics);

    function handleChange(e) {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }

    function handleSave() {
      handleProductPageUpdate({
        statistics: state,
      });
    }

    const Items = ({ data, onChange }) => {
      const [state, setState] = useState(data);

      function handleAdd() {
        onChange([...state, { title: '', text: '', direction: 'up', image: '' }]);
      }

      function handleDelete(title) {
        onChange(state.filter((item) => item.title !== title));
      }

      function handleChange(e, index) {
        const newState = [...state];
        const item = newState[index];
        item[e.target.name] = e.target.value;
        setState(newState);
      }

      if (!state.length) {
        return (
          <IconButton
            size='small'
            color='primary'
            aria-label='Add'
            component='span'
            onClick={() => handleAdd()}
            className={classes.listItemIcon}
          >
            <AddIcon />
          </IconButton>
        );
      }

      return (
        <>
          {state.map(({ title, text, image, direction }, index) => (
            <Box className={classes.benefit} key={index}>
              <Box className={classes.benefitLeft}>
                <ImageInput
                  name='image'
                  label='Image URL'
                  helperText='150x150'
                  className={classes.textField}
                  value={image}
                  onChange={(e) => handleChange(e, index)}
                  onBlur={() => onChange(state)}
                />
                <TextField
                  name='title'
                  label='Title'
                  helperText='70% or 5x'
                  value={title}
                  className={classes.textField}
                  onChange={(e) => handleChange(e, index)}
                  onBlur={() => onChange(state)}
                />
                <TextField
                  name='text'
                  label='Text'
                  value={text}
                  className={classes.textField}
                  onChange={(e) => handleChange(e, index)}
                  onBlur={() => onChange(state)}
                />
                <Select
                  name='direction'
                  value={direction}
                  label='Arrow direction'
                  onChange={(e) => {
                    const newState = [...state];
                    const item = newState[index];
                    item.direction = e.target.value;
                    onChange(newState);
                  }}
                >
                  {['up', 'down'].map((option, index) => (
                    <MenuItem value={option} key={index}>
                      Arrow {option}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box className={classes.listItemIcons}>
                <IconButton
                  color='secondary'
                  aria-label='delete item'
                  component='span'
                  size='small'
                  className={classes.listItemIcon}
                  onClick={() => handleDelete(title)}
                >
                  <DeleteIcon />
                </IconButton>

                <IconButton
                  size='small'
                  color='primary'
                  aria-label='Add'
                  component='span'
                  onClick={() => handleAdd()}
                  className={classes.listItemIcon}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
        </>
      );
    };

    return (
      <Paper className={classes.paper}>
        <Typography className={classes.title}>Statistics</Typography>

        <TextField
          name='title'
          label='Title'
          value={state.title}
          className={classes.textField}
          fullWidth
          onChange={handleChange}
          onBlur={() => handleSave()}
        />
        <TextField
          name='subtitle'
          label='Subtitle'
          value={state.subtitle}
          className={classes.textField}
          fullWidth
          onChange={handleChange}
          onBlur={() => handleSave()}
        />
        <TextField
          name='notes'
          label='Notes'
          value={state.notes}
          className={classes.textField}
          fullWidth
          helperText='Supports HTML'
          multiline
          maxRows={4}
          onChange={handleChange}
          onBlur={() => handleSave()}
        />
        <Items
          data={state.items}
          onChange={(items) =>
            handleProductPageUpdate({
              statistics: { ...state, items },
            })
          }
        />
      </Paper>
    );
  };

  const BottlePatternImage = () => {
    const [url, setUrl] = useState(productPage.bottlePatternImage);

    return (
      <Paper className={classes.paper}>
        <Typography className={classes.title}>Bottle Pattern Image</Typography>
        <ImageInput
          label='Image URL'
          helperText='260x317'
          className={classes.textField}
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          onBlur={() =>
            handleProductPageUpdate({
              bottlePatternImage: url,
            })
          }
        />
      </Paper>
    );
  };

  const DoctorSpoon = () => {
    const [state, setState] = useState(productPage.doctorSpoon);

    function handleChange(e) {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }

    function handleSave() {
      handleProductPageUpdate({
        doctorSpoon: state,
      });
    }

    return (
      <Paper className={classes.paper}>
        <Typography className={classes.title}>Doctor / MD Reviewed</Typography>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <FormGroup className={classes.textField}>
            <FormControlLabel
              control={
                <Switch
                  value={productPage.doctorSpoon.isActive}
                  checked={productPage.doctorSpoon.isActive}
                  onChange={(e) =>
                    handleProductPageUpdate({
                      doctorSpoon: {
                        ...state,
                        isActive: e.target.checked,
                      },
                    })
                  }
                />
              }
              label='Active'
            />
          </FormGroup>
        </div>
        <ImageInput
          name='image'
          label='Image URL'
          className={classes.textField}
          value={state.image}
          onChange={handleChange}
          onBlur={() => handleSave()}
        />
        <TextField
          name='title'
          label='Title'
          value={state.title}
          className={classes.textField}
          fullWidth
          onChange={handleChange}
          onBlur={() => handleSave()}
        />
        <TextField
          name='text'
          label='Text'
          helperText='Supports HTML'
          value={state.text}
          className={classes.textField}
          fullWidth
          onChange={handleChange}
          onBlur={() => handleSave()}
        />
        <TextField
          name='author'
          label='Author'
          helperText='Supports HTML'
          value={state.author}
          className={classes.textField}
          fullWidth
          onChange={handleChange}
          onBlur={() => handleSave()}
        />
      </Paper>
    );
  };

  // Ingredients list
  const IngredientsList = () => {
    const [state, setState] = useState(productPage.ingredientsList);

    function handleAdd() {
      handleProductPageUpdate({
        ingredientsList: [...state, { title: '', text: '', image: '', subtitle: '', bulletPoints: [] }],
      });
    }

    function handleAddBulletPoint(index) {
      const newState = [...state];
      const ingredient = newState[index];
      ingredient.bulletPoints = ingredient.bulletPoints
        ? [...ingredient.bulletPoints, { title: '', text: '' }]
        : [{ title: '', text: '' }];
      setState(newState);
    }

    function handleDeleteBulletPoint(index, bpIndex) {
      const newState = [...state];
      newState[index].bulletPoints = newState[index].bulletPoints.filter((_, i) => i !== bpIndex);
      setState(newState);
      handleProductPageUpdate({
        ingredientsList: newState,
      });
    }

    function handleDelete(title) {
      handleProductPageUpdate({
        ingredientsList: state.filter((item) => item.title !== title),
      });
    }

    function handleChange(e, index, bulletPointIndex = null) {
      const newState = [...state];
      const item = newState[index];
      if (bulletPointIndex !== null) {
        item.bulletPoints[bulletPointIndex][e.target.name] = e.target.value;
      } else {
        item[e.target.name] = e.target.value;
      }
      setState(newState);
    }

    if (!state.length) {
      return (
        <IconButton
          size='small'
          color='primary'
          aria-label='Add'
          component='span'
          onClick={() => handleAdd()}
          className={classes.listItemIcon}
        >
          <AddIcon />
        </IconButton>
      );
    }

    function handleSave() {
      handleProductPageUpdate({
        ingredientsList: state,
      });
    }

    return (
      <>
        {state.map(({ title, text, image, subtitle, bulletPoints }, index) => (
          <Box className={classes.benefit} key={index}>
            <Box className={classes.benefitLeft}>
              <ImageInput
                name='image'
                label='Image URL'
                className={classes.textField}
                value={image}
                onChange={(e) => handleChange(e, index)}
                onBlur={() => handleSave()}
              />
              <TextField
                name='title'
                label='Title'
                value={title}
                className={classes.textField}
                onChange={(e) => handleChange(e, index)}
                onBlur={() => handleSave()}
              />
              <TextField
                name='subtitle'
                label='Subtitle'
                value={subtitle}
                className={classes.textField}
                onChange={(e) => handleChange(e, index)}
                onBlur={() => handleSave()}
              />
              <TextField
                name='text'
                label='Text'
                value={text}
                multiline
                maxRows={4}
                className={classes.textField}
                onChange={(e) => handleChange(e, index)}
                onBlur={() => handleSave()}
              />
              {bulletPoints &&
                bulletPoints.map(({ title: bpTitle, text: bpText }, bpIndex) => (
                  <div key={bpIndex} className={classes.bulletPoint}>
                    <TextField
                      name='title'
                      label='Bullet Point Title'
                      className={classes.textField}
                      value={bpTitle}
                      fullWidth
                      onChange={(e) => handleChange(e, index, bpIndex)}
                      onBlur={() => handleSave()}
                    />
                    <TextField
                      name='text'
                      label='Bullet Point Text'
                      className={classes.textField}
                      value={bpText}
                      multiline
                      minRows={3}
                      fullWidth
                      onChange={(e) => handleChange(e, index, bpIndex)}
                      onBlur={() => handleSave()}
                    />
                    <Button color='secondary' onClick={() => handleDeleteBulletPoint(index, bpIndex)}>
                      Delete Bullet Point
                    </Button>
                  </div>
                ))}
              <Button color='primary' onClick={() => handleAddBulletPoint(index)}>
                Add Bullet Point
              </Button>
            </Box>
            <Box className={classes.listItemIcons}>
              <IconButton
                color='secondary'
                aria-label='delete item'
                component='span'
                size='small'
                className={classes.listItemIcon}
                onClick={() => handleDelete(title)}
              >
                <DeleteIcon />
              </IconButton>

              <IconButton
                size='small'
                color='primary'
                aria-label='Add'
                component='span'
                onClick={() => handleAdd()}
                className={classes.listItemIcon}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
      </>
    );
  };

  // Ingredients
  const Ingredients = () => {
    const [state, setState] = useState(productPage.ingredients);

    function handleChange(e) {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }

    function handleSave() {
      handleProductPageUpdate({
        ingredients: state,
      });
    }

    return (
      <Paper className={classes.paper}>
        <Typography className={classes.title}>Ingredients</Typography>
        <TextField
          name='title'
          label='Title'
          value={state.title}
          className={classes.textField}
          helperText='Supports HTML'
          fullWidth
          onChange={handleChange}
          onBlur={() => handleSave()}
        />
        <TextField
          name='subtitle'
          label='Subtitle'
          value={state.subtitle}
          className={classes.textField}
          helperText='Supports HTML'
          fullWidth
          onChange={handleChange}
          onBlur={() => handleSave()}
        />
        <ImageInput
          name='image'
          label='Image URL'
          className={classes.textField}
          value={state.image}
          onChange={handleChange}
          onBlur={() => handleSave()}
        />
        <Typography variant='h6' align='center' gutterBottom>
          Ingredients List (also used for new template top section tab)
        </Typography>
        <IngredientsList />
      </Paper>
    );
  };

  const OfferSelector = () => {
    function handleProductSelectorUpdate(update) {
      handleProductPageUpdate({ offers: update });
    }

    function renderProductsSelect() {
      const options = stickyProducts
        .map((item) => ({
          id: item.id,
          name: item.name,
          slug: item.slug,
          images: item.images,
          sku: item.sku,
          price: item.price,
          category: item.category,
          sale_price: item.sale_price,
          title: '',
          optionName: `[${item.id}] - ${item.name} `,
          is_variant_enabled: item.is_variant_enabled,
        }))
        .sort((a, b) => a.id - b.id);

      return (
        <Box className={classes.select}>
          <MultiSelect
            label='Sticky Products'
            optionLabel='optionName'
            options={options}
            value={productPage.offers}
            className={classes.select}
            onChange={(values) => {
              handleProductSelectorUpdate(values);
            }}
          />
        </Box>
      );
    }

    const Product = ({ item }) => {
      const [billingModelId, setBillingModelId] = useState(item.billingModelId || '');
      const [label, setLabel] = useState(item.label || '');
      const [tag, setTag] = useState(item.tag || '');
      const [packageSize, setPackageSize] = useState(item.packageSize || '');
      const [groupName, setGroupName] = useState(item.groupName || '');
      const [groupValue, setGroupValue] = useState(item.groupValue || '');

      function handleSaveProduct(e) {
        const index = productPage.offers.findIndex((product) => product.id === item.id);
        const newProducts = [...productPage.offers];
        newProducts[index][e.target.name] = e.target.value;

        handleProductSelectorUpdate(newProducts);
      }

      return (
        <div className={classes.product}>
          <div className={classes.productImage}>
            <img height={75} width={75} alt={item.name} src={getProductDefaultImage(item)} />
          </div>
          <TextField
            label='Product id'
            value={item.id}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            className={classes.textField}
          />
          <TextField
            label='Product name'
            value={item.name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            className={classes.textField}
          />
          <TextField
            label='Subscription Billing Model Id'
            helperText='If not set id 3 is used by default'
            name='billingModelId'
            value={billingModelId}
            onChange={(e) => setBillingModelId(e.target.value)}
            fullWidth
            onBlur={(e) => handleSaveProduct(e)}
            className={classes.textField}
          />
          <TextField
            label='Label'
            name='label'
            value={label}
            helperText='6 bottles, 3 months supply, etc...'
            onChange={(e) => setLabel(e.target.value)}
            fullWidth
            onBlur={(e) => handleSaveProduct(e)}
            className={classes.textField}
          />
          <TextField
            label='Package size'
            name='packageSize'
            value={packageSize}
            helperText='Number of items in the package. 6, 3, etc... '
            onChange={(e) => setPackageSize(e.target.value)}
            fullWidth
            onBlur={(e) => handleSaveProduct(e)}
            className={classes.textField}
          />
          <TextField
            label='Tag'
            name='tag'
            value={tag}
            helperText='Best value, most popular, etc...'
            onChange={(e) => setTag(e.target.value)}
            fullWidth
            onBlur={(e) => handleSaveProduct(e)}
            className={classes.textField}
          />
          <Typography variant='subtitle2' align='center' gutterBottom>
            Grouping settings:
          </Typography>
          <TextField
            fullWidth
            label='Group Name'
            name='groupName'
            value={groupName}
            helperText='Size, flavor, etc...'
            onChange={(e) => setGroupName(e.target.value)}
            onBlur={(e) => handleSaveProduct(e)}
            className={classes.textField}
          />
          <TextField
            fullWidth
            label='Group Value'
            name='groupValue'
            value={groupValue}
            helperText='15 servings, Vanilla, etc...'
            onChange={(e) => setGroupValue(e.target.value)}
            onBlur={(e) => handleSaveProduct(e)}
            className={classes.textField}
          />
        </div>
      );
    };

    function renderProducts() {
      return (
        <Box className={classes.products}>
          {productPage.offers.map((item, index) => (
            <Product item={item} key={index} />
          ))}
        </Box>
      );
    }

    return (
      <Paper className={classes.paper}>
        <Typography className={classes.title}>Offers</Typography>
        {renderProductsSelect()}
        {renderProducts()}
      </Paper>
    );
  };

  return (
    <>
      {(productPageLoading || benefitGroupsLoading || stickyProductsLoading) && (
        <LinearProgress className={classes.progress} />
      )}
      <Container maxWidth='lg' className={classes.container}>
        <Paper className={classes.paper}>
          <Typography className={classes.title}>General settings</Typography>
          <TextField
            label='Id'
            value={productPage._id}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            className={classes.textField}
          />

          <TextField
            label='Slug'
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
            onBlur={() =>
              handleProductPageUpdate({
                slug,
              })
            }
            fullWidth
            className={classes.textField}
          />
          <FormControl fullWidth className={classes.select}>
            <InputLabel>Template</InputLabel>
            <Select
              label='Template'
              value={productPage.template}
              onChange={(e) =>
                handleProductPageUpdate({
                  template: e.target.value,
                })
              }
              fullWidth
              className={classes.textField}
            >
              {[
                { label: 'Default', value: 0 },
                { label: '1', value: 1 },
              ].map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label='Headline'
            value={headline}
            onChange={(e) => setHeadline(e.target.value)}
            onBlur={() =>
              handleProductPageUpdate({
                headline,
              })
            }
            fullWidth
            className={classes.textField}
          />
          <TextField
            label='Subtitle'
            value={subtitle}
            onChange={(e) => setSubtitle(e.target.value)}
            onBlur={() =>
              handleProductPageUpdate({
                subtitle,
              })
            }
            fullWidth
            className={classes.textField}
          />

          <ChipsList
            title='Headline benefits'
            items={headlineBenefits}
            onUpdate={(newItems) =>
              handleProductPageUpdate({
                headlineBenefits: newItems,
              })
            }
          />
          <ChipsList
            title='Top benefits'
            items={topBenefits}
            onUpdate={(newItems) =>
              handleProductPageUpdate({
                topBenefits: newItems,
              })
            }
          />
          <ImageInput
            label='Supplement facts image'
            value={supplementFacts}
            onChange={(e) => setSupplementFacts(e.target.value)}
            className={classes.textField}
            onBlur={() =>
              handleProductPageUpdate({
                supplementFacts,
              })
            }
          />
          <ChipsList
            title='Additional Supplement Facts Images (for bundles)'
            items={productPage.additionalSupplementFacts}
            onUpdate={(newItems) =>
              handleProductPageUpdate({
                additionalSupplementFacts: newItems,
              })
            }
          />

          {renderLabels()}
          {renderBenefitGroups()}
          <TextField
            label='CTA Alert'
            value={ctaAlert}
            onChange={(e) => setCtaAlert(e.target.value)}
            onBlur={() =>
              handleProductPageUpdate({
                ctaAlert,
              })
            }
            fullWidth
            className={classes.textField}
          />
        </Paper>

        <OfferSelector />

        <Ingredients />

        <BottlePatternImage />

        <Problems />

        <Benefits />

        <Paper className={classes.paper}>
          <Typography className={classes.title}>Lovers</Typography>
          {renderLovers()}
        </Paper>

        <Statistics />

        <BigBenefits />

        <DoctorSpoon />

        <Paper className={classes.paper}>
          <Typography className={classes.title}>How to take</Typography>
          <TextField
            fullWidth
            name='howToTakeImage'
            label='How to take image URL'
            value={howToTakeImage}
            helperText='Suggested size: 607x739px'
            className={classes.textField}
            onChange={(e) => setHowToTakeImage(e.target.value)}
            onBlur={() =>
              handleProductPageUpdate({
                howToTakeImage,
              })
            }
          />
          <Typography variant='h6' style={{ textAlign: 'center', marginBottom: '20px' }}>
            Steps:
          </Typography>
          {renderHowToTake()}
        </Paper>

        <Results />

        <Paper className={classes.paper}>
          <Typography className={classes.title}>Comparison table</Typography>
          {renderComparisonTable()}
        </Paper>

        <Paper className={classes.paper}>
          <Typography className={classes.title}>Video Reviews</Typography>
          {renderVideoReviews()}
        </Paper>

        <Paper className={classes.paper}>
          <Typography className={classes.title}>Reviews</Typography>
          {renderReviews()}
        </Paper>

        <Paper className={classes.paper}>
          <Typography className={classes.title}>FAQ</Typography>
          {renderFAQ()}
        </Paper>

        <Paper className={classes.paper}>
          <Typography className={classes.title}>Related products (including this)</Typography>
          {renderRelatedProducts()}
        </Paper>

        <Paper className={classes.paper}>
          <Typography className={classes.title}>Scientific references</Typography>
          <Wysiwyg initialValue={productPage.customSectionContent} onBlur={handleWysiwygBlur} />
        </Paper>
      </Container>
    </>
  );
}

ProductPagePage.propTypes = {
  onRender: PropTypes.func,
};
