import createDataContext from './createDataContext';
import { api } from '../api/api';

const freeProductsReducer = (state, action) => {
  switch (action.type) {
    case 'get_free_products':
      return { ...state, freeProducts: action.payload };
    case 'add_free_product':
      return {
        ...state,
        freeProducts: [...state.freeProducts, action.payload],
      };
    case 'delete_free_product':
      return {
        ...state,
        freeProducts: state.freeProducts.filter((item) => item._id !== action.payload),
      };
    case 'set_loading':
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

const getFreeProducts = (dispatch) => {
  return async () => {
    dispatch({ type: 'set_loading', payload: true });
    try {
      const response = await api.get('/freeProducts', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch({ type: 'get_free_products', payload: response.data });
    } catch (err) {
      console.log(err);
    }
    dispatch({ type: 'set_loading', payload: false });
  };
};

const addFreeProduct = (dispatch) => {
  return async (freeProduct) => {
    try {
      const response = await api.post('/freeProducts', freeProduct, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch({ type: 'add_free_product', payload: response.data });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
};

const deleteFreeProduct = (dispatch) => {
  return async (id) => {
    try {
      await api.delete(`/freeProducts/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch({ type: 'delete_free_product', payload: id });
    } catch (err) {
      console.log(err);
    }
  };
};

export const { Context, Provider } = createDataContext(
  freeProductsReducer,
  { getFreeProducts, addFreeProduct, deleteFreeProduct },
  { freeProducts: [], isLoading: false }
);
