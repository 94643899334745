import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { Context as FreeProductsContext } from '../../context/FreeProductsContext';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  link: {
    textDecoration: 'none',
  },
  actions: {
    display: 'flex',
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
  image: {
    maxWidth: '80px',
    maxHeight: '80px',
  },
}));

const FreeProductsPage = ({ onRender }) => {
  const classes = useStyles();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  const {
    state: { freeProducts, isLoading },
    getFreeProducts,
    deleteFreeProduct,
  } = useContext(FreeProductsContext);

  useEffect(() => {
    onRender('Free Products');
    getFreeProducts();
  }, []);

  const handleDeleteClick = (product) => {
    setProductToDelete(product);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (productToDelete) {
      await deleteFreeProduct(productToDelete._id);
    }
    setOpenDeleteDialog(false);
    setProductToDelete(null);
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
    setProductToDelete(null);
  };

  return (
    <Container className={classes.container}>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography variant='h5'>Free Products</Typography>
          <Link to='/freeProducts/new' className={classes.link}>
            <Button variant='contained' color='primary' startIcon={<AddIcon />}>
              Add Free Product
            </Button>
          </Link>
        </div>

        {isLoading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>MSRP</TableCell>
                  <TableCell>Gift After ($)</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {freeProducts.map((product) => (
                  <TableRow key={product._id}>
                    <TableCell>
                      {product.image && (
                        <img src={product.image} alt={product.name} className={classes.image} />
                      )}
                    </TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.sku}</TableCell>
                    <TableCell>${product.msrp.toFixed(2)}</TableCell>
                    <TableCell>${product.giftAfter}</TableCell>
                    <TableCell>
                      <div className={classes.actions}>
                        <Link to={`/freeProducts/${product._id}`}>
                          <Button variant='contained' size='small' color='primary' startIcon={<EditIcon />}>
                            Edit
                          </Button>
                        </Link>
                        <Button
                          variant='contained'
                          size='small'
                          color='secondary'
                          startIcon={<DeleteIcon />}
                          onClick={() => handleDeleteClick(product)}
                        >
                          Delete
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      <ConfirmationDialog
        open={openDeleteDialog}
        title='Delete Free Product'
        content={`Are you sure you want to delete "${productToDelete?.name}"?`}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </Container>
  );
};

export default FreeProductsPage;
