import createDataContext from './createDataContext';
import { api } from '../api/api';

const freeProductReducer = (state, action) => {
  switch (action.type) {
    case 'get_free_product':
      return { ...state, freeProduct: action.payload };
    case 'update_free_product':
      return { ...state, freeProduct: action.payload };
    case 'add_error':
      return { ...state, errorMessage: action.payload };
    case 'clear_error_message':
      return { ...state, errorMessage: '' };
    case 'set_loading':
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

const getFreeProduct = (dispatch) => {
  return async (id) => {
    dispatch({ type: 'set_loading', payload: true });
    try {
      const response = await api.get(`/freeProducts/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch({ type: 'get_free_product', payload: response.data });
    } catch (err) {
      console.log(err);
    }
    dispatch({ type: 'set_loading', payload: false });
  };
};

const updateFreeProduct = (dispatch) => {
  return async (id, updatedFreeProduct) => {
    try {
      const response = await api.patch(`/freeProducts/${id}`, updatedFreeProduct, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch({ type: 'update_free_product', payload: response.data });
      return response.data;
    } catch (err) {
      dispatch({
        type: 'add_error',
        payload: 'Something went wrong with saving the free product',
      });
      console.log(err);
    }
  };
};

const clearErrorMessage = (dispatch) => {
  return () => {
    dispatch({ type: 'clear_error_message' });
  };
};

export const { Context, Provider } = createDataContext(
  freeProductReducer,
  { getFreeProduct, updateFreeProduct, clearErrorMessage },
  { freeProduct: null, errorMessage: '', isLoading: false }
);
