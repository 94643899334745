import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import Alert from '@material-ui/lab/Alert';
import { Context as FreeProductContext } from '../../context/FreeProductContext';
import { Context as FreeProductsContext } from '../../context/FreeProductsContext';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
  },
  previewImage: {
    maxWidth: '100%',
    maxHeight: '200px',
    marginTop: theme.spacing(2),
  },
}));

const FreeProductPage = ({ onRender }) => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    offer_id: 1,
    billing_model_id: 2,
    billing_model_name: 'One-time',
    product_id: '',
    quantity: 1,
    image: '',
    sku: '',
    gtin: '',
    msrp: '',
    oneTimePrice: 0,
    subscriptionPrice: 0,
    isGift: true,
    giftAfter: '',
    servingSize: '',
    slug: '',
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const {
    state: { freeProduct, isLoading, errorMessage },
    getFreeProduct,
    updateFreeProduct,
    clearErrorMessage,
  } = useContext(FreeProductContext);

  const { addFreeProduct } = useContext(FreeProductsContext);

  useEffect(() => {
    onRender(id === 'new' ? 'New Free Product' : 'Edit Free Product');

    if (id !== 'new') {
      getFreeProduct(id);
    }
  }, [id]);

  useEffect(() => {
    if (freeProduct && id !== 'new') {
      setFormData(freeProduct);
    }
  }, [freeProduct]);

  useEffect(() => {
    if (errorMessage) {
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error',
      });
      clearErrorMessage();
    }
  }, [errorMessage]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : type === 'number' ? parseFloat(value) || 0 : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (id === 'new') {
        await addFreeProduct(formData);
      } else {
        await updateFreeProduct(id, formData);
      }

      setSnackbar({
        open: true,
        message: `Free product ${id === 'new' ? 'created' : 'updated'} successfully!`,
        severity: 'success',
      });

      setTimeout(() => {
        history.push('/freeProducts');
      }, 1500);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'There was an error saving the free product.',
        severity: 'error',
      });
    }
  };

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (isLoading && id !== 'new') {
    return (
      <Container className={classes.container}>
        <Paper className={classes.paper}>
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        </Paper>
      </Container>
    );
  }

  return (
    <Container className={classes.container}>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography variant='h5'>
            {id === 'new' ? 'Create New Free Product' : `Edit ${formData.name}`}
          </Typography>
        </div>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.formField}
                fullWidth
                label='Name'
                name='name'
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.formField}
                fullWidth
                label='SKU'
                name='sku'
                value={formData.sku}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.formField}
                fullWidth
                label='Product ID'
                name='product_id'
                type='number'
                value={formData.product_id}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.formField}
                fullWidth
                label='MSRP ($)'
                name='msrp'
                type='number'
                step='0.01'
                value={formData.msrp}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.formField}
                fullWidth
                label='Gift After ($)'
                name='giftAfter'
                type='number'
                value={formData.giftAfter}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.formField}
                fullWidth
                label='GTIN'
                name='gtin'
                value={formData.gtin}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.formField}
                fullWidth
                label='Image URL'
                name='image'
                value={formData.image}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.formField}
                fullWidth
                label='Serving Size'
                name='servingSize'
                value={formData.servingSize}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.formField}
                fullWidth
                label='Slug'
                name='slug'
                value={formData.slug}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.formField}
                fullWidth
                label='Quantity'
                name='quantity'
                type='number'
                value={formData.quantity}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {formData.image && (
              <Grid item xs={12}>
                <Typography variant='subtitle1'>Image Preview:</Typography>
                <img src={formData.image} alt='Preview' className={classes.previewImage} />
              </Grid>
            )}
          </Grid>

          <div className={classes.buttonContainer}>
            <Button
              variant='contained'
              startIcon={<ArrowBackIcon />}
              onClick={() => history.push('/freeProducts')}
            >
              Back
            </Button>
            <Button type='submit' variant='contained' color='primary' startIcon={<SaveIcon />}>
              Save
            </Button>
          </div>
        </form>
      </Paper>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default FreeProductPage;
