import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import App from './pages/App/App';
import './global.css';

import { Provider as UserProvider } from './context/UserContext';
import { Provider as UsersPageProvider } from './context/UsersContext';

import { Provider as ProductPagesProvider } from './context/ProductPagesContext';
import { Provider as ProductPageProvider } from './context/ProductPageContext';
import { Provider as HomePageProvider } from './context/HomePageContext';
import { Provider as StickyProductsProvider } from './context/StickyProductsContext';
import { Provider as SpecialOffersProvider } from './context/SpecialOffersContext';
import { Provider as SpecialOfferProvider } from './context/SpecialOfferContext';
import { Provider as OrdersProvider } from './context/OrdersContext';
import { Provider as BenefitGroupsProvider } from './context/BenefitGroupsContext';
import { Provider as AnalyticsProvider } from './context/AnalyticsContext';
import { Provider as ProductSelectorsProvider } from './context/ProductSelectorsContext';
import { Provider as ProductSelectorProvider } from './context/ProductSelectorContext';
import { Provider as CrossSellsProvider } from './context/CrossSellsContext';
import { Provider as CrossSellProvider } from './context/CrossSellContext';
import { Provider as WebsitesPageProvider } from './context/WebsitesContext';
import { Provider as FreeProductsProvider } from './context/FreeProductsContext';
import { Provider as FreeProductProvider } from './context/FreeProductContext';

const theme = createTheme({
  props: {
    MuiTextField: {
      variant: 'outlined',
      size: 'small',
    },
    MuiFormControl: {
      variant: 'outlined',
      size: 'small',
    },
    MuiTable: {
      size: 'small',
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        margin: '0 -3px',
        padding: '0 6px',
        backgroundColor: 'white',
      },
    },
    MuiFormHelperText: {
      root: {
        fontStyle: 'italic',
        lineHeight: 'normal',
      },
    },
    MuiContainer: {
      maxWidthLg: { maxWidth: '1440px !important' },
    },
  },
});

ReactDOM.render(
  <UserProvider>
    <UsersPageProvider>
      <WebsitesPageProvider>
        <StickyProductsProvider>
          <HomePageProvider>
            <ProductPagesProvider>
              <ProductPageProvider>
                <SpecialOffersProvider>
                  <SpecialOfferProvider>
                    <OrdersProvider>
                      <BenefitGroupsProvider>
                        <AnalyticsProvider>
                          <ProductSelectorsProvider>
                            <ProductSelectorProvider>
                              <CrossSellsProvider>
                                <CrossSellProvider>
                                  <FreeProductsProvider>
                                    <FreeProductProvider>
                                      <Router>
                                        <ThemeProvider theme={theme}>
                                          <CssBaseline />
                                          <App />
                                        </ThemeProvider>
                                      </Router>
                                    </FreeProductProvider>
                                  </FreeProductsProvider>
                                </CrossSellProvider>
                              </CrossSellsProvider>
                            </ProductSelectorProvider>
                          </ProductSelectorsProvider>
                        </AnalyticsProvider>
                      </BenefitGroupsProvider>
                    </OrdersProvider>
                  </SpecialOfferProvider>
                </SpecialOffersProvider>
              </ProductPageProvider>
            </ProductPagesProvider>
          </HomePageProvider>
        </StickyProductsProvider>
      </WebsitesPageProvider>
    </UsersPageProvider>
  </UserProvider>,

  document.getElementById('root')
);

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState !== 'visible') {
    document.querySelectorAll('input').forEach((item) => {
      item.blur();
    });
  }
});
