import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Paper,
  Box,
  Button,
  IconButton,
  Typography,
  TextField,
  Select,
  MenuItem,
  Checkbox,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { api } from '../../api/api';

import Modal from '../../components/Modal/Modal';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';

const useStyles = makeStyles((theme) => ({
  table: { minWidth: 650 },
  container: { marginTop: theme.spacing(4) },
  topButtons: { display: 'flex', justifyContent: 'flex-end', marginBottom: theme.spacing(2) },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    gap: theme.spacing(2),
  },
}));

export default function DeclineSettingsPage({ onRender }) {
  const classes = useStyles();

  // Local state management
  const [declineSettings, setDeclineSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [topDeclinedParams, setTopDeclinedParams] = useState([]);
  const [topParamsLoading, setTopParamsLoading] = useState(true);
  const [topParamsError, setTopParamsError] = useState(false);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [settingToDelete, setSettingToDelete] = useState(null);

  const parameterNames = [
    'affId',
    'c1',
    'c2',
    'c3',
    'sub1',
    'click_id',
    'gclid',
    'address',
    'email',
    'emailDomain',
    'ipAddress',
  ];

  useEffect(() => {
    loadDeclineSettings();
    loadTopDeclinedParams();
    onRender && onRender('Decline Settings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDeclineSettings = async () => {
    setLoading(true);
    try {
      const response = await api.get('/decline-settings', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setDeclineSettings(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Error loading decline settings:', err);
      setError(true);
      setLoading(false);
    }
  };

  const loadTopDeclinedParams = async () => {
    setTopParamsLoading(true);
    try {
      const response = await api.get('/decline-counts/top', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setTopDeclinedParams(response.data);
      setTopParamsLoading(false);
    } catch (err) {
      console.error('Error loading top declined parameters:', err);
      setTopParamsError(true);
      setTopParamsLoading(false);
    }
  };

  const createDeclineSetting = async (setting) => {
    try {
      const response = await api.post('/decline-settings', setting, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setDeclineSettings([...declineSettings, response.data]);
      return response.data;
    } catch (err) {
      console.error('Error creating decline setting:', err);
      setError(true);
    }
  };

  const updateDeclineSetting = async (id, updatedSetting) => {
    try {
      const response = await api.patch(`/decline-settings/${id}`, updatedSetting, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setDeclineSettings(declineSettings.map((setting) => (setting._id === id ? response.data : setting)));
    } catch (err) {
      console.error('Error updating decline setting:', err);
      setError(true);
    }
  };

  const deleteDeclineSetting = async (id) => {
    try {
      await api.delete(`/decline-settings/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setDeclineSettings(declineSettings.filter((setting) => setting._id !== id));
    } catch (err) {
      console.error('Error deleting decline setting:', err);
      setError(true);
    }
  };

  const handleDeleteSetting = (id) => {
    setDeleteModalOpen(true);
    setSettingToDelete(id);
  };

  const handleAddSetting = async () => {
    // Create a new setting with default values
    const newSettingData = {
      parameterName: '',
      parameterValue: '',
      active: false,
    };
    await createDeclineSetting(newSettingData);
  };

  const handleFieldChange = (id, field, value) => {
    setDeclineSettings(
      declineSettings.map((setting) => (setting._id === id ? { ...setting, [field]: value } : setting))
    );
  };

  const handleParameterValueBlur = async (id) => {
    const setting = declineSettings.find((s) => s._id === id);
    if (setting) {
      await updateDeclineSetting(id, { parameterValue: setting.parameterValue });
    }
  };

  const handleActiveChange = async (id, value) => {
    const setting = declineSettings.find((s) => s._id === id);
    if (!setting.parameterName || !setting.parameterValue) {
      alert('Please fill in both Parameter Name and Parameter Value before activating.');
      return;
    }
    handleFieldChange(id, 'active', value);
    await updateDeclineSetting(id, { active: value });
  };

  if (error) {
    return <Error error='An error occurred while loading decline settings.' />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Modal open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <Box>
          <Typography variant='h6'>Are you sure you want to delete this setting?</Typography>
        </Box>
        <Box className={classes.modalButtons}>
          <Button variant='contained' color='primary' onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={async () => {
              await deleteDeclineSetting(settingToDelete);
              setDeleteModalOpen(false);
            }}
          >
            Delete
          </Button>
        </Box>
      </Modal>

      <Container className={classes.container}>
        <Box className={classes.topButtons}>
          <Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={handleAddSetting}>
            Add Setting
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table className={classes.table} size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Parameter Name</TableCell>
                <TableCell>Parameter Value</TableCell>
                <TableCell>Active</TableCell>
                <TableCell align='right'>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {declineSettings.map((setting) => (
                <TableRow key={setting._id} hover>
                  <TableCell>
                    <Select
                      value={setting.parameterName}
                      onChange={async (e) => {
                        handleFieldChange(setting._id, 'parameterName', e.target.value);
                        await updateDeclineSetting(setting._id, { parameterName: e.target.value });
                      }}
                    >
                      {parameterNames.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={setting.parameterValue}
                      onChange={(e) => handleFieldChange(setting._id, 'parameterValue', e.target.value)}
                      onBlur={async () => await handleParameterValueBlur(setting._id)}
                      helperText='Case sensitive'
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={setting.active}
                      onChange={async (e) => await handleActiveChange(setting._id, e.target.checked)}
                      disabled={!setting.parameterName || !setting.parameterValue}
                    />
                  </TableCell>
                  <TableCell align='right'>
                    <IconButton color='secondary' onClick={() => handleDeleteSetting(setting._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box mt={4} mb={4}>
          <Typography variant='h6' gutterBottom>
            Top 20 Declined Parameters
          </Typography>
          {topParamsLoading ? (
            <Loading />
          ) : topParamsError ? (
            <Error error='An error occurred while loading top declined parameters.' />
          ) : (
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Parameter Name</TableCell>
                    <TableCell>Parameter Value</TableCell>
                    <TableCell>Decline Streak</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topDeclinedParams.map((item, index) => (
                    <TableRow key={index} hover>
                      <TableCell>{item.parameterName}</TableCell>
                      <TableCell>{item.parameterValue}</TableCell>
                      <TableCell>{item.declineStreak}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Container>
    </>
  );
}
