import React, { useContext } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Context as UserContext } from '../../context/UserContext';

import logo from './manna.png';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  logo: {
    width: '75%',
    marginBottom: '10px',
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
  },
});

const links = [
  {
    name: 'Orders',
    path: '/',
  },
  {
    name: 'Product pages',
    path: '/productPages',
  },
  {
    name: 'Sticky products',
    path: '/stickyProducts',
  },
  {
    name: 'Special offers',
    path: '/specialOffers',
  },
  {
    name: 'Free products',
    path: '/freeProducts',
  },
  {
    name: 'Cross-sells',
    path: '/crossSells',
  },
  {
    name: 'Benefit Groups',
    path: '/benefitGroups',
  },
  {
    name: 'Banners',
    path: '/banners',
  },
  {
    name: 'Product selectors',
    path: '/productSelectors',
  },
  {
    name: 'Decline Settings',
    path: '/declineSettings',
  },
  {
    name: 'Websites',
    path: '/websites',
  },
];

export default function MainMenu({ isOpen, onSetOpen }) {
  const classes = useStyles();
  const history = useHistory();

  const {
    state: { user },
  } = useContext(UserContext);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    onSetOpen(open);
  };

  function navigateTo(path) {
    history.push(path);
  }

  const list = () => (
    <div
      className={clsx(classes.list)}
      role='presentation'
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className={classes.logoWrapper}>
        <img src={logo} alt='logo' className={classes.logo} />
        <small>manna.sticky.io</small>
      </div>

      <Divider />
      <List>
        {links.map((item, index) => (
          <ListItem key={index} button onClick={() => navigateTo(item.path)}>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}

        {user?.user.isSuperAdmin && (
          <ListItem button onClick={() => navigateTo('/users')}>
            <ListItemText primary={'Users'} />
          </ListItem>
        )}
      </List>
    </div>
  );

  return (
    <Drawer anchor='left' open={isOpen} onClose={toggleDrawer(false)}>
      {list()}
    </Drawer>
  );
}
